import { createRouter, createWebHashHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/history_chart",
    component: () => import("../components/history_chart/history_chart.vue"),
  },
  {
    path: "/view_board/:id",
    component: () => import("../components/history_chart/view_board.vue"),
  },
  {
    path: "/data_config",
    component: () => import("../components/data_config.vue"),
  },
  {
    path: "/crawller_maneger",
    component: () => import("../components/crawller_maneger.vue"),
  },
  // {
  //   path: "/api_setting",
  //   component: () => import("../components/api_setting.vue"),
  // },
  // {
  //   path: "/base_setting",
  //   component: () => import("../components/base_setting.vue"),
  // },
  // {
  //   path: "/user_edit",
  //   component: () => import("../components/user_edit.vue"),
  // },
  // {
  //   path: "/user",
  //   component: () => import("../components/user.vue"),
  // },
  // {
  //   path: "/role",
  //   component: () => import("../components/role.vue"),
  // },
  // {
  //   path: "/role_permission",
  //   component: () => import("../components/role_permission.vue"),
  // },
];

// createWebHistory
// createWebHashHistory
// createMemoryHistory
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
