import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";

function setup() {
  const is_login = ref(false);
  const set = (ui) => {
    localStorage.setItem("tislab_back", JSON.stringify(ui));
  };
  const get = () => {
    let token = null;
    if (localStorage.getItem("tislab_back") === null) {
      token = null;
    } else {
      let access_token = JSON.parse(
        localStorage.getItem("tislab_back")
      ).access_token;
      let payload = access_token.split(".")[1];
      let user_info = JSON.parse(atob(payload));
      if (new Date().getTime() / 1000 > user_info.exp) {
        remove();
        token = null;
      } else {
        token = JSON.parse(localStorage.getItem("tislab_back"));
      }
    }
    return token;
  };
  const remove = () => {
    is_login.value = false;
    localStorage.removeItem("tislab_back");
  };
  const genertate_uuid = () => {
    let u = uuidv4();
    localStorage.setItem("tislab_back_uuid", u);
    return u;
  };
  const get_uuid = () => {
    let u = localStorage.getItem("tislab_back_uuid");
    if (u == null) {
      u = genertate_uuid();
    }
    return u;
  };

  return {
    is_login,
    set,
    get,
    remove,
    get_uuid,
    genertate_uuid,
  };
}
const ui = setup();
if (ui.get() !== null) {
  let user_info = ui.get();
  if (user_info) {
    ui.is_login.value = true;
  }
}
export default ui;
