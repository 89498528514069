import ui from "./userinfo.js";

export default [
  {
    name: "Charting",
    key: "menu_spreadchart",
    type: "menu",
    contents: [
      // {
      //   name: "Daily",
      //   key: "daily_chart",
      //   link: "/daily_chart",
      // },
      {
        name: "History Chart",
        key: "History_chart",
        link: "/history_chart",
      },
    ],
  },
  {
    name: "Crawller Maneger",
    key: "Crawller_Maneger",
    type: "link",
    link: "/Crawller_Maneger",
  },
  {
    name: "Data Config",
    key: "data_config",
    type: "link",
    link: "/data_config",
  },
  {
    name: "Exit",
    key: "exit",
    type: "button",
    link: "/",
    click: (callback) => {
      ui.remove();
      callback();
    },
  },
];
