<!-- eslint-disable no-unused-vars -->
<template>
  <div id="app">
    <div class="login_background" v-if="!is_login">
      <div class="login_form">
        <img src="tis.png" class="login_form_logo" />
        <h2 style="text-align:center;margin:30px 0px">
          恬谷内部研究终端
        </h2>
        <a-form sytle="login_form" name="Login" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
          <a-form-item :rules="[{ required: true, message: 'Please input your username!' }]">
            <a-input v-model:value="username" placeholder="Please Enter Your User Name">
            </a-input>
          </a-form-item>
          <a-form-item :rules="[{ required: true, message: 'Please input your password!' }]">
            <a-input-password v-model:value="password" placeholder="Please Enter Your Password">
            </a-input-password>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" style="width:100%" @click="login">Submit</a-button>
          </a-form-item>
          <p style="text-align:center"><a-button type="link"
              href="https://beian.miit.gov.cn/">粤ICP备2024214792号-1</a-button>
          </p>
        </a-form>
      </div>
    </div>
    <a-layout v-else>
      <a-layout-header>
        <div class="logo" />
      </a-layout-header>
      <a-float-button type="primary" :style="{
        right: '24px',
      }" @click="logout">
        <!-- <template #icon>
          <QuestionCircleOutlined />
        </template> -->
      </a-float-button>
      <a-layout-content style="padding: 0 50px;min-height:80vh">
        <a-breadcrumb style="margin: 16px 0;height:5vh">
          <a-breadcrumb-item>Home</a-breadcrumb-item>
          <a-breadcrumb-item>List</a-breadcrumb-item>
          <a-breadcrumb-item>App</a-breadcrumb-item>
        </a-breadcrumb>
        <a-layout style="padding: 24px 0; background: #fff;min-height:100vh">
          <a-layout-sider width="200" style="background: #fff">
            <a-menu mode="inline" style="height: 100%">
              <template v-for="m in menu">
                <a-sub-menu v-if='m.type == "menu"' :key="m.key">
                  <template #title>
                    <span>
                      {{ m.name }}
                    </span>
                  </template>
                  <a-menu-item v-for="c in m.contents" :key="c.key"><router-link :to="c.link">{{ c.name
                      }}</router-link></a-menu-item>
                </a-sub-menu>
                <a-menu-item v-if='m.type == "button"' :key="m.key" @click="() => m.click(() => {
                  is_login = false;
                })">
                  {{ m.name }}
                </a-menu-item>
                <a-menu-item v-if='m.type == "link"' :key="m.key">
                  <router-link :to="m.link">
                    {{ m.name }}
                  </router-link>
                </a-menu-item>
              </template>
            </a-menu>
          </a-layout-sider>
          <a-layout-content :style="{ padding: '0 24px', minHeight: '280px' }">
            <router-view />
          </a-layout-content>
        </a-layout>
      </a-layout-content>
      <a-layout-footer style="text-align: center;height:10vh">
        Tis Lab ©2023 Created by Tislab
      </a-layout-footer>
    </a-layout>
  </div>
</template>


<script setup>
import { ref, inject } from "vue";
import ui from "./userinfo.js";
import menu from "./sider_menu.js";

const axios = inject("axios");
const username = ref("admin")
const password = ref("123654")
const is_login = ref(false);


const logout = () => {
  ui.remove();
  is_login.value = false;
};

is_login.value = ui.is_login.value
const login = () => {
  axios
    .post("/user/login", { account: username.value, password: password.value })
    .then((r) => {
      if (r.data.status == 1) {
        ui.is_login.value = true;
        is_login.value = true
        ui.set(r.data.data);
      }
    });
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}


.login_background {
  min-height: 100vh;
  background-color: rgb(0, 132, 255);
  display: flex;
  align-items: center;
  justify-content: center;

  .login_form {
    display: inline-block;
    width: 500px;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 50px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);

    .login_form_logo {
      width: 100%;
    }
  }
}



.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
</style>
